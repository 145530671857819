import * as React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { RecoilRoot } from 'recoil';

import { initAuth } from '@src/firebase/init-auth';
import { Footer } from '@src/routes/home/components/Footer/Footer';

import '../styles/globals.css';

// Must be called to use Firebase authentication in server and client.
initAuth();

type ApplicationProps = AppProps;

const Application: React.FC<ApplicationProps> = (props, _context) => {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <title>Plant-based Recipes</title>
        <meta name="description" content="Delicious plant based recipes" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
      </Head>

      <RecoilRoot>
        <Component {...pageProps} />
      </RecoilRoot>
      <Footer />
    </>
  );
};

export { Application, Application as default };
export type { ApplicationProps };
