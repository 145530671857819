import { init } from 'next-firebase-auth';
import { APP_NAME } from '@src/config/constants';

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;

/**
 * @name initAuth
 * @description Initialize Firebase Auth for our app. Needs to be added to the
 * `pages/_app.tsx` file.
 * @external https://github.com/gladly-team/next-firebase-auth
 */
export function initAuth() {
  /**
   * For some reason we NEED to hard code these values or we can't use them
   * on the client. My guess it it's some thing with Next.js and how it handles
   * the environment variables which is different from the client.
   * TODO gavalas - it would be great to have all these in env variables.
   */
  const apiKey = 'AIzaSyAXk0k2kAKNhmthJpiaq7clvRLXGH1IjZI';
  const projectId = 'recipes-f5e7f';
  const authDomain = `${projectId}.firebaseapp.com`;
  const databaseURL = `https://${projectId}.firebaseio.com`;

  init({
    appPageURL: '/admin',
    authPageURL: '/sign-in',
    cookies: {
      httpOnly: true,
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      maxAge: TWELVE_DAYS_IN_MS,
      name: APP_NAME,
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    },
    // debug: true,
    firebaseAdminInitConfig: {
      credential: {
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL ?? '',
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
          : undefined,
        projectId,
      },
      databaseURL,
    },
    firebaseAuthEmulatorHost: process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATORS
      ? 'localhost:9099'
      : undefined,
    firebaseClientInitConfig: {
      apiKey,
      authDomain,
      databaseURL,
      projectId,
    },
    loginAPIEndpoint: '/api/auth/sign-in',
    logoutAPIEndpoint: '/api/auth/sign-out',
    onTokenRefreshError: (err) => {
      console.error(err);
    },
    onVerifyTokenError: (err) => {
      console.error(err);
    },
  });
}
